import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Notiflix from 'notiflix';
import {useMutation} from "react-query";
import axios from "axios";
import {useResetMutation} from "./useForgetPassMutation";
import {useForm} from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/CloseButton";
import pagesRoutes from "../../routes/pages-routes";
import Flex from "../../components/common/Flex";

// const reset = async data => {
//     const res = await axios.post('/app/forgot-password', data);
//     console.log(JSON.stringify(res) )
//     // console.log(res.data)
//     return res.data
// };

const DEFAULT_STATE_FORM = {
    email: ''
};

const ForgetPasswordPage = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: DEFAULT_STATE_FORM
    });
    const { mutate: handleReset } = useResetMutation();
  const [modalText, setModalText] = useState('');
  const mutation = useMutation(
      async (data) =>await axios.post('/app/forgot-password', data),
      {
        onSuccess: (res) => {
            setIsModalOpen(true)

            console.log(res)
            if (res.data.success === 1)
            {
                setModalText(`An email is sent successfully`)
            } else {
                setModalText(res.data.error)
            }
        },
        onError: (error) => {
            Notiflix.Notify.failure(error)
        },
          onSettled: (data)=> {
            console.log(data)
          },
      }
  );

  const onSubmit = data => {
      handleReset(data)
      mutation.mutate(data)
    // // todo wait for API
    // console.log(e.target[0].value)
    // if (email) {
    //   Notiflix.Notify.success(`An email is sent to ${email} with password reset link`);
    // }
  };

  return (
    <div className="text-center">
      <h5 className="mb-0"> Forgot your password?</h5>
      <small>Enter your email and we'll send you a reset link.</small>
      <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Control
            placeholder="Email address"
            name="email"
            type="text"
            {...register('email')}
            isInvalid={!!errors.email}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Button className="w-100" type="submit">
            Send reset link
          </Button>
        </Form.Group>
      </Form>
        <Flex justifyContent='center' >
            <Link to={pagesRoutes.login}>Back to Login<span className="d-inline-block ms-1"> &rarr;</span></Link>
        </Flex>
            <Modal
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title  id="contained-modal-title-vcenter" >wqe</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => setIsModalOpen(false)}
                    />
                </Modal.Header>
                <Modal.Body >
                    <div className='text-center'>
                        <p>{modalText}</p></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setIsModalOpen(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

    </div>

  );
};

export default ForgetPasswordPage;
