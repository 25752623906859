import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, Navbar } from 'react-bootstrap';
import classNames from 'classnames';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import Logo from 'components/common/Logo';
import UserName from 'components/common/UserName';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import CreditsDropdown from './CreditsDropdown';

const NavbarTop = () => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext(AppContext);

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    (navbarPosition === 'top' || navbarPosition === 'double-top') && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') && setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <Navbar
      className={classNames('navbar-glass fs--1 navbar-top sticky-kit', { 'navbar-glass-shadow': showDropShadow })}
      expand={navbarPosition === 'top' || navbarPosition === 'combo' || navbarPosition === 'double-top' ? topNavbarBreakpoint : true}
    >
      {/*<NavbarTopElements navbarCollapsed={navbarCollapsed} navbarPosition={navbarPosition} handleBurgerMenu={handleBurgerMenu} />*/}
      <Navbar.Toggle
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none': navbarPosition === 'top' || navbarPosition === 'double-top',
          [`d-${navbarBreakPoint}-none`]: navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        <button className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center" onClick={handleBurgerMenu} id="burgerMenu">
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" width={40} id="topLogo" />
      <Nav navbar className={`align-items-center d-none d-${topNavbarBreakpoint}-block`} as="ul"></Nav>
      <Nav navbar className="navbar-nav-icons ms-auto flex-row align-items-center" as="ul">
        <CreditsDropdown />
        <UserName />
        <ProfileDropdown />
      </Nav>
    </Navbar>
  );
};

const NavbarTopElements = ({ navbarPosition, handleBurgerMenu }) => {
  return (
    <>
      <Navbar.Toggle
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none': navbarPosition === 'top' || navbarPosition === 'double-top',
          [`d-${navbarBreakPoint}-none`]: navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        <button className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center" onClick={handleBurgerMenu} id="burgerMenu">
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" width={40} id="topLogo" />
      <Nav navbar className={`align-items-center d-none d-${topNavbarBreakpoint}-block`} as="ul"></Nav>
      <Nav navbar className="navbar-nav-icons ms-auto flex-row align-items-center" as="ul">
        <UserName />
        <ProfileDropdown />
      </Nav>
    </>
  );
};

NavbarTopElements.propTypes = {
  navbarPosition: PropTypes.string,
  handleBurgerMenu: PropTypes.func,
  navbarCollapsed: PropTypes.bool
};
export default NavbarTop;
