import React, { useState, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useGetProjectFilesQuery } from 'modules/project-view/queries/useGetProjectFilesQuery';
import { useGetProjectFieldsQuery } from 'pages/project-view-page/queries/useGetProjectFieldsQuery';
import ReactTable from 'components/common/ReactTable/ReactTable';
import { Loader } from 'components/common/Loader';
import ReactTablePagination from 'components/common/ReactTable/ReactTablePagination';
import getUrlPath from 'helpers/getUrlPath';

const FilesSpecModal = ({ isVisible, closeModal, extractSelectedFiles, mode = 'fields' }) => {
  const [selectedFields, setSelectedFields] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const rowsPerPage = mode === 'fields' ? 8 : 5;
  const { id } = useParams();

  const {
    data: fieldsData,
    isLoading: isFieldsLoading,
    isError: isFieldsLoadingError
  } = useGetProjectFieldsQuery(id, {
    skip: !isVisible || mode !== 'fields'
  });
  const {
    data: filesData,
    isLoading: isFilesLoading,
    isError: isFilesLoadingError
  } = useGetProjectFilesQuery(id, {
    skip: !isVisible || mode !== 'files'
  });

  const isLoading = mode === 'fields' ? isFieldsLoading : isFilesLoading;
  const isError = mode === 'fields' ? isFieldsLoadingError : isFilesLoadingError;
  const finaleFieldsData = mode === 'fields' ? fieldsData : filesData?.data;
  const finaleFields = Array.isArray(finaleFieldsData) ? finaleFieldsData : [];

  const handleCloseModal = () => {
    closeModal();
  };

  const toggleFieldSelection = fieldId => {
    setSelectedFields(prevSelected => {
      if (prevSelected.includes(fieldId)) {
        return prevSelected.filter(id => id !== fieldId);
      } else {
        return [...prevSelected, fieldId];
      }
    });
  };

  const toggleAllFields = () => {
    if (selectedFields.length === finaleFields.length) {
      setSelectedFields([]);
    } else {
      setSelectedFields(finaleFields.map(field => field._id));
    }
  };

  const columns =
    mode === 'fields'
      ? [
          {
            id: 'checkbox',
            header: () => <input type="checkbox" checked={selectedFields.length === finaleFields.length} onChange={toggleAllFields} />,
            cell: ({ row }) => (
              <input
                type="checkbox"
                className="mx-2 cursor-pointer"
                checked={selectedFields.includes(row.original._id)}
                onChange={() => toggleFieldSelection(row.original._id)}
              />
            )
          },
          {
            accessorKey: 'name',
            header: 'Name',
            id: 'name',
            cell: rowData => (
              <button type="button" className="text-start outline-none border-0 p-0 bg-transparent">
                {rowData.row.original.name}
              </button>
            )
          },
          {
            accessorKey: 'validation',
            header: 'Validation',
            id: 'validation',
            cell: rowData => (
              <button className="outline-none border-0 p-0 bg-transparent" type="button">
                {rowData.row.original.type}
              </button>
            )
          }
        ]
      : [
          {
            id: 'checkbox',
            header: () => <input type="checkbox" checked={selectedFields.length === finaleFields.length} onChange={toggleAllFields} />,
            cell: ({ row }) => (
              <input
                type="checkbox"
                className="mx-2 cursor-pointer"
                checked={selectedFields.includes(row.original._id)}
                onChange={() => toggleFieldSelection(row.original._id)}
              />
            )
          },
          {
            accessorKey: 'file',
            header: 'File',
            id: 'file',
            enableSorting: false,
            cell: rowData => {
              const { imageISfile, _id } = rowData.row.original;
              const path = getUrlPath() + imageISfile;
              return (
                <span className="cursor-pointer" onClick={() => toggleFieldSelection(_id)}>
                  <img src={path} alt="File image" width={150} />
                </span>
              );
            }
          },
          {
            accessorKey: 'name',
            header: 'Name',
            id: 'name',
            enableColumnFilter: true,
            cell: rowData => {
              const { name, _id } = rowData.row.original;
              return (
                <div className="cursor-pointer d-flex text-start" onClick={() => toggleFieldSelection(_id)}>
                  {name}
                </div>
              );
            }
          },
          {
            accessorKey: 'status',
            header: 'Status',
            id: 'status',
            cell: rowData => {
              const { status, _id } = rowData.row.original;
              return (
                <div className="cursor-pointer d-flex" onClick={() => toggleFieldSelection(_id)}>
                  {status}
                </div>
              );
            },
            enableColumnFilter: true
          }
        ];

  const handleSubmit = () => {
    if (selectedFields.length > 0) {
      extractSelectedFiles({ selectedFields });
    }
    handleCloseModal();
  };

  const paginatedFields = useMemo(() => {
    const startIdx = (pageNumber - 1) * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    return finaleFields.slice(startIdx, endIdx);
  }, [finaleFields, pageNumber, rowsPerPage]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>Error loading data</p>;
  }

  return (
    <Modal size="lg" show={isVisible} onHide={handleCloseModal} aria-labelledby="manage-rules-modal">
      <Modal.Header>
        <Modal.Title id="manage-rules-modal">{mode === 'fields' ? 'Choose fields to extract' : 'Choose files'}</Modal.Title>
        <FalconCloseButton onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        {finaleFields.length === 0 ? (
          <p>No data available</p>
        ) : (
          <>
            <ReactTable
              data={paginatedFields || []}
              columns={columns}
              loading={isLoading}
              tableClassName="rules-table"
              getTrProps={row => ({
                onClick: () => toggleFieldSelection(row.original._id),
                style: {
                  cursor: 'pointer',
                  backgroundColor: selectedFields.includes(row.original._id) ? '#f5f5f5' : 'white'
                }
              })}
            />
            {finaleFields.length > rowsPerPage && (
              <ReactTablePagination
                setPageNumber={setPageNumber}
                total={finaleFields.length}
                perPage={rowsPerPage}
                pageNumber={pageNumber}
              />
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={selectedFields.length === 0}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

FilesSpecModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  extractSelectedFiles: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['fields', 'files'])
};

export default FilesSpecModal;
