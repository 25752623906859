import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import getUrlPath from 'helpers/getUrlPath';
import { highlightText } from './tableUtils';

const MAX_LENGTH = 25;

export const createBaseColumns = ({
  files = [],
  deletedIds,
  toggleAll,
  toggleIdsSelection,
  debouncedFilter,
  rowClickHandler,
  padlockHandler
}) => [
  {
    accessorKey: '_id',
    id: 'checkbox',
    header: () => (
      <label className="mb-0 ps-2">
        <input type="checkbox" checked={deletedIds.length === files.length && files.length > 0} onChange={toggleAll} />
      </label>
    ),
    cell: ({ row }) => (
      <input
        type="checkbox"
        className="mx-2"
        checked={deletedIds.includes(row.original._id)}
        onChange={() => toggleIdsSelection(row.original._id)}
      />
    ),
    sortingFn: (rowA, rowB) => {
      const isCheckedA = deletedIds.includes(rowA.original._id);
      const isCheckedB = deletedIds.includes(rowB.original._id);

      if (isCheckedA && !isCheckedB) return -1;
      if (!isCheckedA && isCheckedB) return 1;
      return 0;
    }
  },
  {
    accessorKey: 'protectedYN',
    header: 'Lock',
    id: 'protectedYN',
    cell: rowData => {
      const { protectedYN, _id } = rowData.row.original;
      return (
        <div className="p-0 d-flex align-items-center justify-content-center cursor-pointer">
          <FontAwesomeIcon
            onClick={() => padlockHandler(_id, protectedYN)}
            className="cursor-pointer"
            icon={protectedYN === '1' ? faLock : faLockOpen}
          />
        </div>
      );
    }
  },
  {
    accessorKey: 'file',
    header: 'File',
    id: 'file',
    enableSorting: false,
    cell: rowData => {
      const { imageISfile, _id } = rowData.row.original;
      const path = getUrlPath() + imageISfile;
      return (
        <span className="cursor-pointer" onClick={() => rowClickHandler(_id)}>
          <img src={path} alt="File image" width={150} />
        </span>
      );
    }
  },
  {
    accessorKey: 'name',
    header: 'Name',
    id: 'name',
    enableColumnFilter: true,
    cell: rowData => {
      const { name, _id } = rowData.row.original;
      return (
        <div className="cursor-pointer d-flex text-start" onClick={() => rowClickHandler(_id)}>
          {highlightText(name, debouncedFilter)}
        </div>
      );
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    id: 'status',
    cell: rowData => {
      const { status, _id } = rowData.row.original;
      return (
        <div className="cursor-pointer d-flex" onClick={() => rowClickHandler(_id)}>
          {highlightText(status, debouncedFilter)}
        </div>
      );
    },
    enableColumnFilter: true
  },
  {
    accessorKey: 'dataAdded',
    header: 'Data Added',
    id: 'dataAdded',
    cell: rowData => {
      const { _dateadded, _id } = rowData.row.original;
      return (
        <span className="cursor-pointer" onClick={() => rowClickHandler(_id)}>
          {highlightText(_dateadded, debouncedFilter)}
        </span>
      );
    },
    enableColumnFilter: true,
    sortingFn: (rowA, rowB) => {
      const dateA = new Date(rowA.original._dateadded);
      const dateB = new Date(rowB.original._dateadded);
      return dateA - dateB;
    }
  },
  {
    accessorKey: 'extractedText',
    header: 'Extracted Text',
    id: 'extractedText',
    cell: rowData => {
      const { extracted_textISsmallplaintextbox } = rowData.row.original;
      const [isExpanded, setIsExpanded] = useState(false);

      useEffect(() => {
        if (debouncedFilter && extracted_textISsmallplaintextbox.toLowerCase().includes(debouncedFilter.toLowerCase())) {
          setIsExpanded(true);
        }
      }, [debouncedFilter, extracted_textISsmallplaintextbox]);

      const toggleText = () => {
        setIsExpanded(!isExpanded);
      };

      const shouldTruncate = extracted_textISsmallplaintextbox && extracted_textISsmallplaintextbox.length > MAX_LENGTH;
      const truncatedText = shouldTruncate
        ? `${extracted_textISsmallplaintextbox.slice(0, MAX_LENGTH)}...`
        : extracted_textISsmallplaintextbox;

      return (
        <div className="cursor-pointer d-flex flex-column" onClick={toggleText}>
          {isExpanded || !shouldTruncate
            ? highlightText(extracted_textISsmallplaintextbox, debouncedFilter)
            : highlightText(truncatedText, debouncedFilter)}
          {shouldTruncate && <span style={{ fontSize: '0.8em' }}>{isExpanded ? 'Show less...' : 'Show more...'}</span>}
        </div>
      );
    },
    sortingFn: (rowA, rowB) => {
      const valueA = rowA.original.extracted_textISsmallplaintextbox || '';
      const valueB = rowB.original.extracted_textISsmallplaintextbox || '';

      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);
      const isNumA = !isNaN(numA);
      const isNumB = !isNaN(numB);

      if (isNumA && isNumB) {
        return numA - numB;
      } else if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
        return new Date(valueA) - new Date(valueB);
      } else {
        return valueA.localeCompare(valueB);
      }
    }
  }
];
