import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-medium-image-zoom/dist/styles.css';
import Notiflix from 'notiflix';
import { AuthContext } from 'context/Context';
import FalconRoutes from 'routes/Routes';
import { getSession, getSettings } from 'utils/api';
import { Loader } from 'components/common/Loader';
import APP_USER from 'constants/APP_USER';
import APP_SETTINGS from 'constants/APP_SETTINGS';

const App = () => {
  const [appSettings, setAppSettings] = useState(APP_SETTINGS);
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState(APP_USER);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = appSettings.favicon_16ISfile.uri;
    }
  }, [appSettings?.favicon_16ISfile.uri]);

  useEffect(() => {
    getSettings()
      .then(res => setAppSettings(res))
      .catch(err => Notiflix.Notify.failure(err.message));
    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = appSettings.favicon_16ISfile.uri;
    }
  }, []);

  useEffect(() => {
    getSession()
      .then(res => {
        setUser({
          userID: res?._id,
          first_name: res?.first_name,
          last_name: res?.last_name
        });
        setAuth(true);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <section className="loader-section">
        <Loader />
      </section>
    );
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AuthContext.Provider value={{ appSettings, auth, loading, setAuth, user, setUser }}>
        <FalconRoutes />
      </AuthContext.Provider>
    </Router>
  );
};

export default App;
