import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import Notiflix from 'notiflix';
import axios from 'axios';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDebounce from 'hooks/useDebounce';
import './FilesTable.scss';
import { Loader } from 'components/common/Loader';
import ReactTablePagination from './FilesTablePagination';
import { useLockFileMutation } from 'hooks/mutations/useLockFileMutation';
import FilesBulkActions from 'modules/project-view/FilesBulkActions';
import { createBaseColumns } from './baseColumns';
import { highlightText } from './tableUtils';

export const getFiles = async ({ projectISbb_datasheep_projectsID, limit, page }) => {
  const params = {
    projectISbb_datasheep_projectsID,
    page,
    limit
  };
  const res = await axios.get(`/app/bb_datasheep_projects_files`, { params });
  return res.data;
};

const FilesTable = ({ loading, maxHeight = '650px', tableClassName = '' }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [deletedIds, setDeletedIds] = useState([]);
  const [files, setFiles] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const [sorting, setSorting] = useState([]);

  const debouncedFilter = useDebounce(globalFilter, 800);

  const padlockHandler = (id, protection) => {
    const data = { protectedYN: protection === '1' ? '0' : '1' };
    lockFile({ id, data });
  };

  const rowClickHandler = id => {
    navigate(`/fileView/${id}`);
  };

  const toggleIdsSelection = useCallback(
    id => {
      setDeletedIds(prev => {
        const currentIndex = prev.indexOf(id);
        const newCheckedItems = [...prev];
        if (currentIndex === -1) {
          newCheckedItems.push(id);
        } else {
          newCheckedItems.splice(currentIndex, 1);
        }
        return newCheckedItems;
      });
    },
    [setDeletedIds]
  );

  const toggleAll = useCallback(() => {
    setDeletedIds(prev => {
      const ids = files.map(file => file._id);
      if (prev.length === ids.length) {
        return [];
      } else {
        return ids;
      }
    });
  }, [files]);

  const baseColumns = useMemo(
    () => createBaseColumns({ files, deletedIds, toggleAll, toggleIdsSelection, debouncedFilter, rowClickHandler, padlockHandler }),
    [files, deletedIds, toggleAll, toggleIdsSelection, debouncedFilter, rowClickHandler, padlockHandler]
  );

  const { mutate: lockFile } = useLockFileMutation(id);

  const { data = { data: [] }, isLoading } = useQuery({
    queryFn: () => getFiles({ projectISbb_datasheep_projectsID: id, page: 0, limit: 10000 }),
    queryKey: ['GET_PROJECT_FILES', id],
    refetchOnWindowFocus: false,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    placeholderData: true
  });

  useEffect(() => {
    if (data?.data) {
      setFiles(data.data);
    }
  }, [data?.data]);

  const customIncludesString = (row, columnId, filterValue) => {
    const rowData = Object.values(row.original).concat((row.original.fields || []).map(field => field.valueISsmallplaintextbox));

    return rowData.some(value => value?.toString().toLowerCase().includes(filterValue.toLowerCase()));
  };

  const toggleSorting = column => {
    const isSortedAsc = column.getIsSorted() === 'asc';
    const isSortedDesc = column.getIsSorted() === 'desc';

    if (!isSortedAsc && !isSortedDesc) {
      setSorting([{ id: column.id, desc: false }]);
    } else if (isSortedAsc) {
      setSorting([{ id: column.id, desc: true }]);
    } else if (isSortedDesc) {
      setSorting([{ id: column.id, desc: false }]);
    }
  };

  const dynamicColumns = useMemo(() => {
    if (data?.data?.length > 0) {
      const allFieldsWithOrder = data.data.reduce((accumulator, currentFile) => {
        currentFile.fields?.forEach(field => {
          if (!accumulator.some(f => f.field_name === field.field_name)) {
            accumulator.push({
              field_name: field.field_name,
              orderNUM: field.orderNUM !== null ? Number(field.orderNUM) : Infinity
            });
          }
        });
        return accumulator;
      }, []);

      const sortedFields = allFieldsWithOrder.sort((a, b) => a.orderNUM - b.orderNUM);

      return sortedFields.map((field, idx) => ({
        accessorKey: field.field_name,
        header: field.field_name,
        id: `field-${idx}`,
        cell: rowData => {
          const fieldValue = rowData.row.original.fields?.find(f => f.field_name === field.field_name);
          const value = fieldValue ? fieldValue.valueISsmallplaintextbox : '';

          const [isExpanded, setIsExpanded] = useState(false);

          useEffect(() => {
            if (debouncedFilter && value && value.toLowerCase().includes(debouncedFilter.toLowerCase())) {
              setIsExpanded(true);
            }
          }, [debouncedFilter, value]);

          const toggleText = () => setIsExpanded(!isExpanded);

          const shouldTruncate = value && value.length > 55;
          const truncatedText = shouldTruncate ? `${value.slice(0, 25)}...` : value;

          return (
            <div className="cursor-pointer d-flex flex-column" onClick={toggleText}>
              {isExpanded || !shouldTruncate ? highlightText(value, debouncedFilter) : highlightText(truncatedText, debouncedFilter)}
              {shouldTruncate && <span style={{ fontSize: '0.8em' }}>{isExpanded ? 'Show less...' : 'Show more...'}</span>}
            </div>
          );
        },
        enableColumnFilter: true,
        filterFn: customIncludesString,
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original.fields?.find(f => f.field_name === field.field_name)?.valueISsmallplaintextbox || '';
          const valueB = rowB.original.fields?.find(f => f.field_name === field.field_name)?.valueISsmallplaintextbox || '';

          const numA = parseFloat(valueA);
          const numB = parseFloat(valueB);
          const isNumA = !isNaN(numA);
          const isNumB = !isNaN(numB);

          if (isNumA && isNumB) {
            return numA - numB;
          } else if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
            return new Date(valueA) - new Date(valueB);
          } else {
            return valueA.localeCompare(valueB);
          }
        }
      }));
    }
    return [];
  }, [data, debouncedFilter]);

  const combinedColumns = useMemo(() => [...baseColumns, ...dynamicColumns], [baseColumns, dynamicColumns]);

  const clearInput = () => {
    setGlobalFilter('');
  };

  const table = useReactTable({
    data: data.data || [],
    columns: combinedColumns,
    state: {
      sorting,
      globalFilter: debouncedFilter
    },
    onSortingChange: setSorting,
    globalFilterFn: customIncludesString,
    filterFns: { customIncludesString },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  const resetToggle = useCallback(() => {
    setDeletedIds([]);
  }, [setDeletedIds]);

  if (loading || isLoading) {
    return (
      <div className="d-flex flex-column flex-grow-1">
        <Loader />
      </div>
    );
  }

  if (data.length === 0) {
    return <h5>No data to display</h5>;
  }

  return (
    <>
      <div className="mb-3 d-flex gap-2 justify-content-between">
        <div className="flex-grow-1" style={{ flexBasis: '50%' }}>
          <InputGroup className="position-relative">
            <FormControl
              value={globalFilter}
              onChange={e => setGlobalFilter(e.target.value)}
              size="sm"
              id="search-file-field"
              placeholder="Search..."
              className="shadow-none py-2 form-control"
            />
            {globalFilter && (
              <Button
                variant="link"
                className="position-absolute end-0 top-50 translate-middle-y text-decoration-none"
                onClick={clearInput}
                style={{ zIndex: 10, marginRight: '2.5rem' }}
              >
                <FontAwesomeIcon icon="times" className="fs--1" />
              </Button>
            )}
            <Button size="sm" variant="outline-secondary" className="border-300 hover-border-secondary">
              <FontAwesomeIcon icon="search" className="fs--1" />
            </Button>
          </InputGroup>
        </div>
        <div className="flex-grow-1" style={{ flexBasis: '40%' }}>
          <FilesBulkActions selectedRowIds={deletedIds} resetToggle={resetToggle} />
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="position-relative" style={{ zIndex: 0 }}>
          <div className="table-wrapper" style={{ maxHeight }}>
            <table className={'react-table ' + tableClassName}>
              <thead className="sticky-top bg-400 px-2">
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th
                        className="text-left align-middle py-3 px-2"
                        key={header.id}
                        onClick={event => {
                          event.persist();
                          toggleSorting(header.column);
                        }}
                      >
                        <div className="d-inline-flex gap-1">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          <span className="content-center">
                            {header.column.getIsSorted() === 'asc' ? (
                              <FontAwesomeIcon className="mb-0" style={{ cursor: 'pointer' }} icon="sort-amount-up" />
                            ) : header.column.getIsSorted() === 'desc' ? (
                              <FontAwesomeIcon className="mb-0" style={{ cursor: 'pointer' }} icon="sort-amount-down" />
                            ) : (
                              <FontAwesomeIcon className="mb-0" style={{ cursor: 'pointer' }} icon="sort" />
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td className="py-1 px-2 text-start align-middle fs--1" key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row py-2 mt-2 justify-content-end gap-2">
        <ReactTablePagination
          pageCount={table.getPageCount()}
          pageIndex={table.getState().pagination.pageIndex}
          setPageIndex={table.setPageIndex}
          table={table}
        />
      </div>
    </>
  );
};

FilesTable.propTypes = {
  loading: PropTypes.bool,
  isSticky: PropTypes.bool,
  maxHeight: PropTypes.string,
  tableClassName: PropTypes.string
};

export default FilesTable;
