import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom';
import PropTypes from 'prop-types';
import Notiflix from 'notiflix';
import getUrlPath from 'helpers/getUrlPath';
import { useTextExtractMutation } from 'hooks/mutations/useTextExtractMutation';
import { useChangeBalanceMutation } from 'hooks/mutations/useChangeBalanceMutation';
import { useSaveExtractedTextMutation } from 'modules/file-view/mutations/useSaveExtractedTextMutation';
import ModalExtract from 'components/common/ModalExtract';

const FileViewReExtractText = ({ file }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [text, setText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBodyText, setModalBodyText] = useState('');
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progress, setProgress] = useState(0);

  const { mutate: extractText } = useTextExtractMutation(id, {
    onMutate: () => {
      setModalTitle('Extracting…');
      setModalBodyText('Large datasets may take some time to complete. You can close this window and return later.');
      setShowProgressBar(true);
      setShowModal(true);
      setProgress(0);
    },
    onSuccess: async () => {
      changeBalance();
      setProgress(100);
      setModalTitle('Success');
      setModalBodyText('Extraction was completed.');
      setShowProgressBar(false);
      queryClient.invalidateQueries('credits');
    },
    onError: error => {
      setModalTitle('Error');
      setModalBodyText(`An error occurred while extracting text. Code: ${error.response?.status || 'N/A'}, Type: ${error.name || 'N/A'}`);
      setShowProgressBar(false);
    },
    onSettled: () => {
      setTimeout(() => setShowModal(true));
    },
    setProgress
  });

  const { mutate: handleSaveText, isLoading } = useSaveExtractedTextMutation();

  const { mutate: changeBalance } = useChangeBalanceMutation({
    onSuccess: data => {
      console.log('Balance updated successfully', data);
    },
    onError: error => {
      Notiflix.Notify.failure('Failed to update balance');
      console.error('Failed to update balance', error);
    }
  });

  useEffect(() => {
    setText(file?.extracted_textISsmallplaintextbox ?? '');
  }, [file?.extracted_textISsmallplaintextbox]);

  const TextExtractHandler = () => {
    const data = {
      fileISbb_datasheep_projects_filesID: id
    };
    extractText(data);
  };

  const handleSave = () => {
    const value = {
      id,
      data: {
        projectISbb_datasheep_projectsID: file.projectISbb_datasheep_projectsID,
        extracted_textISsmallplaintextbox: text
      }
    };
    handleSaveText(value);
  };

  const handleSetText = e => {
    const { value } = e.target;
    setText(value);
  };

  return (
    <>
      <Row>
        <Col lg={4} md={12}>
          <div className="files-page-img-wrapper mb-4">
            <Zoom>
              <img src={getUrlPath() + `${file.imageISfile}`} alt={file.name} className="mw-100" />
            </Zoom>
          </div>
        </Col>
        <Col lg="8" md="12">
          <Row className="align-items-center justify-content-between mb-3">
            <Col>
              <h5 className="m-0">Extracted Text</h5>
            </Col>
            <Col className="text-end">
              <Button onClick={TextExtractHandler} variant="falcon-primary">
                Re-extract Text
              </Button>
            </Col>
          </Row>
          <Form.Control
            as="textarea"
            type="text"
            rows={12}
            className={`resize-none ${isLoading ? 'opacity-50' : 'opacity-100'}`}
            value={text}
            onChange={handleSetText}
            onBlur={handleSave}
          />
        </Col>
      </Row>

      <ModalExtract
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        bodyText={modalBodyText}
        showProgressBar={showProgressBar}
        progress={progress}
      />
    </>
  );
};

FileViewReExtractText.propTypes = {
  file: PropTypes.object.isRequired
};

export default FileViewReExtractText;
