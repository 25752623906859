import axios from "axios";
import {useMutation} from "react-query";
import Notiflix from "notiflix";
import {loadingSvgColor} from "../../helpers/utils";

const reset = async data => {
    const res = await axios.post('/app/forgot-password', data);
    console.log(res.data)
    return res.data
};

export const useResetMutation = () => {
    // const navigate = useNavigate();
    // const client = useQueryClient();
    // const [showModal, setShowModal] = useState(false);
    // const [message, setMessage] = useState('');
    const { mutate } = useMutation({
        mutationFn: reset,
        onMutate: () => {
            Notiflix.Loading.hourglass({ svgColor: loadingSvgColor });
        },
        onError: error => {
            console.log('OnError:',error.message)
            Notiflix.Notify.failure(error)
            // return alert(error.message)
        },
        onSuccess: (res) => {
            console.log(res)
        },
        onSettled: () => {
            Notiflix.Loading.remove();
        }
    });
    return { mutate };
};