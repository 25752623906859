import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ProgressBar } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';

const ModalExtract = ({ show, onHide, title, bodyText, showProgressBar, progress }) => {
  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        <CloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <p>{bodyText}</p>
          {showProgressBar && <ProgressBar now={progress} label={`${progress}%`} />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalExtract.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  showProgressBar: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired
};

export default ModalExtract;
