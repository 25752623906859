import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, Col, Collapse, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Notiflix from 'notiflix';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { useExtractFilesMutation } from 'hooks/mutations/useExtractFilesMutation';
import { useChangeBalanceMutation } from 'hooks/mutations/useChangeBalanceMutation';
import { useEditFieldMutation } from 'pages/file-view/mutations/useEditFieldMutation';
import ModalExtract from 'components/common/ModalExtract';

const FileViewExtractedFields = ({ file, handleEditRule }) => {
  const { id } = useParams();

  const queryClient = useQueryClient();

  const [isLockSaving, setLockSaving] = useState(false);
  const [fieldsDrops, setFieldsDrops] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBodyText, setModalBodyText] = useState('');
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progress, setProgress] = useState(0);

  const data = { fileISbb_datasheep_projects_filesID: id };

  const onMutateHandler = () => {
    setModalTitle('Extracting…');
    setModalBodyText('Large datasets may take some time to complete. You can close this window and return later.');
    setShowProgressBar(true);
    setShowModal(true);
    setProgress(0);
  };

  const onErrorHandler = error => {
    setModalTitle('Error');
    setModalBodyText(`An error occurred while extracting data. Code: ${error.response?.status || 'N/A'}, Type: ${error.name || 'N/A'}`);
    setShowProgressBar(false);
  };

  const onSettledHandler = () => {
    setTimeout(() => setShowModal(true));
  };

  const onSuccessHandler = () => {
    setProgress(100);
    setModalTitle('Success');
    setModalBodyText('Extraction was completed');
    setShowProgressBar(false);
  };

  const { mutate: extractFiles } = useExtractFilesMutation(id, {
    onMutate: onMutateHandler,
    setProgress,
    onError: onErrorHandler,
    onSuccess: async () => {
      changeBalance();
      onSuccessHandler();
      queryClient.invalidateQueries('credits');
    },
    onSettled: onSettledHandler
  });

  const { mutate: handleUpdateField, isLoading: isFieldSaving } = useEditFieldMutation(id, {
    onMutate: onMutateHandler,
    onError: onErrorHandler,
    onSuccess: onSuccessHandler,
    onSettled: onSettledHandler
  });

  const { mutate: changeBalance } = useChangeBalanceMutation({
    onSuccess: data => {
      console.log('Balance updated successfully', data);
    },
    onError: error => {
      Notiflix.Notify.failure('Failed to update balance');
      console.error('Failed to update balance', error);
    }
  });

  const extractHandler = () => {
    extractFiles(data);
  };

  const onPadlockClick = clickId => {
    const currentField = fieldsDrops.find(el => el.id === clickId);
    const data = {
      fileISbb_datasheep_projects_filesID: id,
      fieldISbb_datasheep_projects_fieldsID: currentField.fieldISbb_datasheep_projects_fieldsID,
      protectedYN: currentField.protectedYN === '1' ? '0' : '1'
    };
    setLockSaving(true);
    handleUpdateField(
      { id: clickId, data },
      {
        onSuccess: () => {
          setLockSaving(false);
        }
      }
    );
  };

  const onReExtract1Field = clickID => {
    const data = {
      fileISbb_datasheep_projects_filesID: id,
      fieldISbb_datasheep_projects_fieldsID: clickID
    };
    extractFiles(data);
  };

  const onEditRules = fieldID => {
    handleEditRule(fieldID);
  };

  const calculateRows = content => {
    const newlineCount = (content.match(/\n/g) || []).length;
    const contentIndex = Math.ceil(content.length / 136);
    return Math.max(newlineCount + contentIndex, 1);
  };

  useEffect(() => {
    if (file?.fields) {
      let dropsData = file.fields.map(el => ({
        id: el._id,
        fieldISbb_datasheep_projects_fieldsID: el.fieldISbb_datasheep_projects_fieldsID,
        name: el.field_name,
        value: el.valueISsmallplaintextbox === null ? '' : el.valueISsmallplaintextbox,
        protectedYN: el.protectedYN,
        orderNUM: el.orderNUM,
        isOpen: true
      }));

      const sortedData = dropsData.sort((a, b) => {
        const orderNumA = a.orderNUM !== null ? Number(a.orderNUM) : Infinity;
        const orderNumB = b.orderNUM !== null ? Number(b.orderNUM) : Infinity;
        return orderNumA - orderNumB;
      });

      setFieldsDrops(sortedData);
    } else {
      setFieldsDrops([]);
    }
  }, [file]);

  const handleToggleVisibility = id => {
    setFieldsDrops(prev => prev.map(oldEl => (oldEl.id === id ? { ...oldEl, isOpen: !oldEl.isOpen } : oldEl)));
  };

  const handleSetFieldValue = (e, id) => {
    const { value } = e.target;
    setFieldsDrops(prevState => prevState.map(oldEl => (oldEl.id === id ? { ...oldEl, value } : oldEl)));
  };

  const handleSaveField = fieldId => {
    const obj = fieldsDrops.find(el => el.id === fieldId);

    const data = {
      fileISbb_datasheep_projects_filesID: id,
      fieldISbb_datasheep_projects_fieldsID: obj.fieldISbb_datasheep_projects_fieldsID,
      valueISsmallplaintextbox: obj.value
    };
    handleUpdateField({ id: fieldId, data });
  };

  return (
    <>
      <Row className="align-items-center justify-content-between mb-4 mt-5">
        <Col>
          <h5>Extracted Fields</h5>
        </Col>
        <Col className="text-end">
          <Button onClick={extractHandler} variant="falcon-primary">
            Re-extract fields
          </Button>
        </Col>
      </Row>
      {!file?.fields?.length && <p>No fields attached yet</p>}
      {file?.fields?.length > 0 && (
        <>
          {fieldsDrops.length > 0 &&
            fieldsDrops.map(el => (
              <div key={el.id}>
                <div className="mb-2 d-flex justify-content-between">
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      className={classNames('me-2 mb-0 cursor-pointer', { 'lock-disabled': isLockSaving })}
                      onClick={() => onPadlockClick(el.id)}
                    >
                      <FontAwesomeIcon icon={el.protectedYN === '1' ? faLock : faLockOpen} />
                    </div>
                    <div
                      className="d-flex justify-content-around align-items-center"
                      onClick={() => handleToggleVisibility(el.id)}
                      aria-controls="example-collapse-text"
                      aria-expanded={el.isOpen}
                    >
                      <FontAwesomeIcon icon={el.isOpen ? faChevronDown : faChevronRight} className="cursor-pointer mx-2" />
                      <h5 style={{ display: 'inline-block' }} className="files-page-field mb-0" title={el.name}>
                        {el.name}
                      </h5>
                    </div>
                  </div>
                  <div className="text-end" style={{ maxWidth: '200px' }}>
                    <Button
                      className="m-1"
                      variant="falcon-primary"
                      size="sm"
                      onClick={() => onReExtract1Field(el.fieldISbb_datasheep_projects_fieldsID)}
                    >
                      Re-Extract
                    </Button>
                    <Button
                      className="m-1"
                      variant="falcon-primary"
                      size="sm"
                      onClick={() => onEditRules(el.fieldISbb_datasheep_projects_fieldsID)}
                    >
                      Rules
                    </Button>
                  </div>
                </div>
                <Collapse in={el.isOpen}>
                  <div>
                    <Form.Control
                      as="textarea"
                      type="text"
                      rows={calculateRows(el.value)}
                      className="files-page-collapse-textarea"
                      style={{ opacity: isFieldSaving ? '0.5' : '1' }}
                      value={el.value}
                      onBlur={() => handleSaveField(el.id)}
                      onChange={e => handleSetFieldValue(e, el.id)}
                    />
                  </div>
                </Collapse>
              </div>
            ))}
        </>
      )}

      <ModalExtract
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        bodyText={modalBodyText}
        showProgressBar={showProgressBar}
        progress={progress}
      />
    </>
  );
};

FileViewExtractedFields.propTypes = {
  file: PropTypes.object,
  handleEditRule: PropTypes.func.isRequired
};

export default FileViewExtractedFields;
