import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import Notiflix from 'notiflix';
import classNames from 'classnames';
import './ProjectBoxes.css';
import Flex from 'components/common/Flex';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { GaugeChart } from 'echarts/charts';
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { CanvasRenderer } from 'echarts/renderers';
import CreateNewProjectModal from 'modules/dashboard/CreateNewProjectModal';
import { useCopyProject } from 'modules/dashboard/mutations/useCopyProject';
import Starter from 'modules/dashboard/Starter';
import { useDeleteProjectMutation } from 'modules/dashboard/mutations/useDeleteProjectMutation';
import { usePadlockClickMutation } from 'modules/dashboard/mutations/usePadlockClickMutation';
import { useRenameProjectMutation } from 'modules/dashboard/mutations/useRenameProjectMutation';
import { useGetProjectsQuery } from 'pages/dashboard-page/queries/useGetProjectsQuery';
import { Loader } from 'components/common/Loader';
import DeleteModal from 'components/common/DeleteModal';

echarts.use([TitleComponent, TooltipComponent, GridComponent, GaugeChart, CanvasRenderer, LegendComponent]);

const getOptions = data => ({
  series: [
    {
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      radius: '90%',
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          color: getColor('primary')
        }
      },
      axisLine: {
        lineStyle: {
          width: 8,
          color: [[1, getColor('gray-200')]]
        }
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      data: [
        {
          value: data,
          detail: {
            offsetCenter: ['7%', '4%']
          }
        }
      ],
      detail: {
        width: 50,
        height: 14,
        fontSize: 28,
        fontWeight: 500,
        fontFamily: 'poppins',
        color: getColor('gray-500'),
        formatter: '{value}%'
      }
    }
  ]
});

const ProjectBoxes = () => {
  const [selectedId, setSelectedId] = useState(null);
  const { data: projects, isLoading, isError } = useGetProjectsQuery();
  const [isCreateProjectModalVisible, setIsCreateProjectModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteProjectModalVisible] = useState(false);

  const queryClient = useQueryClient();
  const existedProjects = queryClient.getQueryData(['ALL_PROJECTS']);
  const { mutate: deleteProject } = useDeleteProjectMutation();
  const { mutate: projectUpdate } = usePadlockClickMutation();
  const { mutate: renameProject } = useRenameProjectMutation();
  const { mutate: copyProject } = useCopyProject();

  const showCreateProjectModal = useCallback(() => {
    setIsCreateProjectModalVisible(true);
  }, []);

  const hideCreateProjectModal = useCallback(() => {
    setIsCreateProjectModalVisible(false);
  }, []);

  const showDeleteModal = id => {
    setSelectedId(id);
    setIsDeleteProjectModalVisible(true);
  };
  const handleDeleteProject = useCallback(id => {
    deleteProject(id);
    setSelectedId(null);
  }, []);

  const handlePadlockClick = useCallback(project => {
    const data = { protectedYN: project.protectedYN === '1' ? '0' : '1' };
    projectUpdate({ id: project.id, data });
  }, []);

  const handleProjectRename = useCallback(id => {
    const name = prompt('Please enter your new name');
    const projectExists = existedProjects.some(project => project.name.toLowerCase() === name.toLowerCase());

    if (projectExists) {
      return Notiflix.Notify.warning(`${name} name is already in projects`);
    }
    if (name.trim() != null && name.trim() !== '') {
      const data = { name };
      renameProject({ id, data });
    }
  }, []);

  const handleCopyProject = useCallback(id => {
    copyProject(id);
    return;
  }, []);

  if (isLoading) {
    return (
      <section className="loader-section">
        <Loader />
      </section>
    );
  }

  if (isError) {
    return <h1>Error while loading data</h1>;
  }

  if (projects.length === 0) {
    return (
      <>
        <Starter showModal={showCreateProjectModal} />
        <CreateNewProjectModal hideModal={hideCreateProjectModal} isModalVisible={isCreateProjectModalVisible} />
      </>
    );
  }

  return (
    <>
      <Row xs={1} md={2} lg={3} className="g-4" role="boxList">
        <Col>
          <Card
            onClick={showCreateProjectModal}
            className=" bg-light cursor-pointer h-100 d-flex align-items-center justify-content-center"
          >
            <Card.Text className="py-5">Create new project</Card.Text>
          </Card>
        </Col>
        {projects.map(el => (
          <Col key={el.id} role="boxListItem">
            <Card>
              <FalconCardHeader
                light
                title={
                  <div className="d-flex align-items-center">
                    <span className="me-2 cursor-pointer" onClick={() => handlePadlockClick(el)}>
                      <FontAwesomeIcon icon={el.protectedYN === '1' ? faLock : faLockOpen} />
                    </span>
                    <Link to={`/projectView/${el.id}`} title={el.name} className="project-boxes-truncate-text">
                      {el.name}
                    </Link>
                  </div>
                }
                titleTag="h6"
                className="py-2"
                endEl={
                  <CardDropdown onCopy={handleCopyProject} onDelete={handleDeleteProject} onRename={handleProjectRename} id={el.id}>
                    <Dropdown.Item onClick={() => handleCopyProject(el.id)}>Copy project</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleProjectRename(el.id)}>Rename</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className="text-danger" onClick={() => showDeleteModal(el.id)}>
                      Delete
                    </Dropdown.Item>
                  </CardDropdown>
                }
              />
              <Link to={`/projectView/${el.id}`}>
                <Card.Body className={classNames('h-100')} as={Flex} direction="column" alignItems="between">
                  <ReactEChartsCore echarts={echarts} option={getOptions(el.extracted)} style={{ height: '10.3rem' }} />
                </Card.Body>
              </Link>

              <Card.Footer className="bg-light py-2">
                <Row className="flex-between-center">
                  <Col xs="auto">
                    <Link className="fs--1 font-sans-serif" to={`/projectView/${el.id}`}>
                      Files: {el.files}
                    </Link>
                  </Col>
                  <Col xs="auto">
                    <Link className="fs--1 font-sans-serif" to={`/projectView/${el.id}`}>
                      Extracted Files: {el.files_extracted}
                    </Link>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
      <CreateNewProjectModal hideModal={hideCreateProjectModal} isModalVisible={isCreateProjectModalVisible} />
      <DeleteModal
        onShow={isDeleteModalVisible}
        onHide={() => setIsDeleteProjectModalVisible(false)}
        id={selectedId}
        onDelete={handleDeleteProject}
      />
    </>
  );
};

export default ProjectBoxes;
