import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faLock, faLockOpen, faSpinner } from '@fortawesome/free-solid-svg-icons';
import './FileViewPage.css';
import FileViewPageInfoTable from 'modules/file-view/FileViewPageInfoTable';
import FileViewReExtractText from 'modules/file-view/FileViewReExtractText';
import ManageFieldsModal from 'modules/project-view/ManageFieldsModal/ManageFieldsModal';
import FileViewExtractedFields from 'modules/file-view/FileViewExtractedFields';
import { useLockFileMutation } from 'hooks/mutations/useLockFileMutation';
import { Loader } from 'components/common/Loader';
import { useGetProjectFileQueryDetails } from 'pages/file-view/queries/useGetProjectFileQueryDetails';

const FileViewPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isRulesModalVisible, setRulesModalVisible] = useState(false);
  const [field, setField] = useState(null);

  const { data: file, isLoading, isError } = useGetProjectFileQueryDetails(id);
  const { mutate: handleLockFile, isLoading: isLockUpdating } = useLockFileMutation(file?.projectISbb_datasheep_projectsID);

  const onBackClick = () => {
    navigate(`/projectView/${file.projectISbb_datasheep_projectsID}`);
  };

  const onPrevClick = () => {
    if (!file.prev_id) {
      return;
    }
    navigate(`/fileView/${file.prev_id}`);
  };

  const onNextClick = () => {
    if (!file.next_id) {
      return;
    }
    navigate(`/fileView/${file.next_id}`);
  };

  const onHighPadlockClick = () => {
    const data = { protectedYN: file.protectedYN === '1' ? '0' : '1' };
    handleLockFile({ id, data });
  };
  const handleEditRule = fieldID => {
    setField(fieldID);
    setRulesModalVisible(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.empty');
      if (header) {
        if (window.scrollY > 0) {
          header.classList.add('scrolled');
        } else {
          header.classList.remove('scrolled');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (isLoading) {
    return (
      <section className="loader-section">
        <Loader />
      </section>
    );
  }

  if (isError) {
    return <h2>Error while loading data</h2>;
  }

  return (
    <>
      <Card className="bg-light">
        <div className="empty"></div>
        <Card.Header className="pb-0 pt-0" style={{ position: 'relative' }}>
          <div className="fixed-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="falcon-primary" size="sm" onClick={onBackClick}>
              <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
              Back to project
            </Button>

            <div className="text-end">
              <Button variant="falcon-primary" size="sm" onClick={onNextClick} disabled={!file.next_id}>
                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                Previous
              </Button>
              <Button variant="falcon-primary ms-3" size="sm" onClick={onPrevClick} disabled={!file.prev_id}>
                Next
                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
              </Button>
            </div>
          </div>
          <div className="p-0 d-flex align-items-center mt-6 gap-2">
            {isLockUpdating ? (
              <FontAwesomeIcon className="spinner" icon={faSpinner} />
            ) : (
              <FontAwesomeIcon
                onClick={onHighPadlockClick}
                className="cursor-pointer"
                icon={file.protectedYN === '1' ? faLock : faLockOpen}
              />
            )}
            <h3 className="m-0">{file.name}</h3>
          </div>
        </Card.Header>

        <Card.Body>
          <Card className="shadow-none mb-4">
            <FileViewPageInfoTable file={file} />
          </Card>
          <FileViewReExtractText file={file} />
          <FileViewExtractedFields file={file} handleEditRule={handleEditRule} />
        </Card.Body>

        <Card.Footer className="pb-30"></Card.Footer>
      </Card>
      {isRulesModalVisible && (
        <ManageFieldsModal
          isFileView={true}
          fieldId={field}
          closeModal={() => {
            setRulesModalVisible(false);
            setField(null);
          }}
        />
      )}
    </>
  );
};
export default FileViewPage;
