import { useMutation } from 'react-query';
import axios from 'axios';

export const register = async data => {
  const res = await axios.post(`/app/signup`, data);
  return res.data;
};

export const useRegisterMutation = () => {
  const { mutate } = useMutation({
    mutationFn: register
  });
  return { mutate };
};

export const useModalMutation = () => {};
